import React from "react"
import Layout from "../components/layout/Layout"
import Button from "../components/Button"

export default () => {
  return (
    <Layout>
      <div className="error-page">
        <h1>
          Stránka <span className="bold">nenalezena</span>
        </h1>
        <p>
          Stránka nebyla nalezena, pravděpodobně jste zadal(a) špatný odkaz.
        </p>
        <Button link="/">Jít na domovskou stránku</Button>
      </div>
    </Layout>
  )
}
